<template>
  <div v-if="getVisibleDoWin">
    <div class="notification-popup notification-popup-dowin">
      <div class="notification-popup-content notification-popup-content-dowin">
        <div class="close" @click.capture="closePopup('XButtonClose')" />
        <div @click="goToDoWinUrl">
          <img :src="getDoWinImage" alt="notification icon" />
          <p class="text top">Görevleri Tamamla</p>
          <p class="text bottom">{{ getDoWinPoint }} Puan Kazan</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'DoWinPopup',
  computed: {
    ...mapGetters('app', ['getPopups']),
    getVisibleDoWin() {
      return this.getPopups.popupId == 4 && this.$route.fullPath == '/secure/feed';
    },
    getDoWinImage() {
      return this.getPopups?.popupDetail?.image ?? '';
    },
    getDoWinPoint() {
      return this.getPopups?.popupDetail?.point ?? '';
    },
  },
  methods: {
    setEventData(isClick) {
      return {
        event: 'cEvent',
        category: 'Pop Up',
        action: isClick ? 'Click' : 'Close',
        label: 'DoWin',
        value: 0,
        isNonInteraction: false,
      };
    },
    closePopup(action) {
      const eventData = this.setEventData(false);
      this.$emit('closePopup', eventData, { type: action });
    },
    goToDoWinUrl() {
      this.$router.push(`${this.getPopups.popupDetail.url}`);

      const eventData = this.setEventData(true);
      this.$emit('closePopup', eventData, { type: 'LinkClick' });
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-popup {
  position: absolute;
  top: 0;
  right: -5px;
  left: -5px;
  bottom: 0;
  z-index: -1;
  background-color: #fff;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &-dowin {
    left: 5px;
    right: 5px;
  }
  &-content {
    position: absolute;
    top: -300px;
    right: -40px;
    width: 323px;
    .text {
      position: absolute;
      left: 20px;
      font-weight: 500;
      &.top {
        font-size: 16px;
        bottom: 78px;
        color: #ffbfbf;
      }
      &.bottom {
        font-size: 24px;
        bottom: 45px;
        color: #fff;
      }
    }
    &-dowin {
      left: -56px;
      right: auto;
      top: -250px !important;
      .text {
        &.top {
          color: #23303d;
        }
        &.bottom {
          color: #fc9507;
        }
      }
    }
    .close {
      position: absolute;
      padding: 5px;
      left: 0;
      top: 0;
      color: #fff;
      width: 45px;
      height: 45px;
      cursor: pointer;
      transition: opacity 0.2s ease;
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 20px;
        left: 15px;
        width: 16px;
        height: 2px;
        background-color: #fff;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(135deg);
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>
